<template>
    <!-- ComponentLibrary 组件库 -->
    <div class="component-library">
        <div class="library-type">
            <div
                v-for="(item, index) in libraryList"
                :key="index"
                :class="['library-type-item', { current: item.id === libraryTypeId }]"
                @click="handlerClickLibraryTypeItem(item)"
            >
                {{ item.title }}
                <span class="des" v-if="item.id == 'kitLibrary'" @click="openDes">
                    <img :src="des" alt="套件库" />
                </span>
            </div>
        </div>
        <div class="library-detail" v-show="libraryTypeId == 'componentLibrary'">
            <Description text="系统套件" />
            <draggable
                class="components-draggable"
                :list="commonComponents"
                :group="{name: 'componentsGroup', pull: 'clone', put: false}"
                :clone="cloneComponent"
                draggable=".components-item"
                :sort="false"
                @start="onStart"
                @end="onEnd"
            >
                <div
                    v-for="(element, index) in commonComponents"
                    :key="index"
                    :class="element.draggable ? 'components-item' : 'draggable'"
                    @click="addComponent(element)"
                >
                    <div class="icon-wrap">
                        <i :class="`iconfont-oa icon-${getIconName(element.compType)}`"></i>
                    </div>
                    <span class="comp-name">{{ element.compName }}</span>
                </div>
            </draggable>
            <Description text="自定义组件" />
            <draggable
                class="components-draggable"
                :list="formItems"
                :group="{name: 'componentsGroup', pull: 'clone', put: false}"
                :clone="cloneComponent"
                draggable=".components-item"
                :sort="false"
                @start="onStart"
                @end="onEnd"
            >
                <div
                    v-for="(element, index) in formItems"
                    :key="index"
                    class="components-item"
                    @click="addComponent(element)"
                >
                    <div class="icon-wrap">
                        <i :class="`iconfont-oa icon-${getIconName(element.compType)}`"></i>
                    </div>
                    <span class="comp-name">{{ element.compName }}</span>
                </div>
            </draggable>
        </div>
        <div class="library-detail" v-show="libraryTypeId == 'kitLibrary'">
            <!-- 套件库 -->
            <div class="des-wrapper">
                <div class="kits-des" v-if="showDes">
                    <div class="kits-title">
                        <span>什么是套件？</span>
                        <span @click="showDes = false">
                            <img class="close" :src="close" alt="关闭" />
                        </span>
                    </div>
                    <p class="kits-content">
                        套件是OA审批自带的控件组合，方便 企业设置审批模板，暂不支持编辑。
                    </p>
                </div>
            </div>
            <draggable
                class="components-draggable"
                :list="componentLibraryList"
                :group="{name: 'componentsGroup', pull: 'clone', put: false}"
                :clone="cloneComponent"
                draggable=".components-item"
                :sort="false"
                @start="onStart"
                @end="onEnd"
            >
                <div
                    v-for="(element, index) in componentLibraryList"
                    :key="index"
                    :class="element.draggable ? 'components-item' : 'draggable'"
                    @click="addComponent(element)"
                >
                    <div class="icon-wrap">
                        <i :class="`iconfont-oa icon-${getIconName(element.compType)}`"></i>
                    </div>
                    <span class="comp-name">{{ element.compName }}</span>
                </div>
            </draggable>
        </div>
    </div>
</template>
<script>
import Title from "./Sub/Title.vue";
import Description from "./Sub/Description.vue";
// 拖拽组件
import draggable from "vuedraggable";
// 生成随机
import {getSimpleId} from "@/libs/utils.js";
import jsonData from "@/assets/css/formDesign/iconfont.json"
const icons = jsonData.glyphs.map(i => i.font_class)
let tempActiveData;

export default {
    name: "ComponentLibrary",
    components: {
        Title,
        Description,
        draggable,
    },
    props: {
        formItems: Array,
        commonComponents: Array,
        componentLibraryList: Array,
        layoutFormItems: Array,
        designListClone: Array,
        libraryTypeId: String,
        libraryList: Array
    },
    created() {
        console.log(jsonData, '================');
        this.designList = this.designListClone;
        console.log(this.designList, "this.designList");
    },
    data() {
        return {
            designList: [],
            activeData: {},
            showDes: false,
        };
    },
    computed: {
        formData: function () {
            const list = this.designList;
            const config = this.formConfig;
            let formData = {};
            formData.list = list;
            formData.config = config;
            console.log(formData);
            return JSON.stringify(formData);
        },
        dynamicTableExist() {
            return function (element) {
                return (
                    this.formConfig.dynamicTableAllowed &&
                    this.designList.filter(
                        (item) => item.compType === "dynamicTable",
                    ).length > 0 &&
                    dynamicTableAllowedItems.includes(element.compType)
                );
            };
        },
        des () {
            return require('@/assets/images/des.png')
        },
        close () {
            return require('@/assets/images/close1.png')
        }
    },
    methods: {
        cloneComponent(origin) {
            const clone = JSON.parse(JSON.stringify(origin));
            if (!clone.layout) clone.layout = "colItem";
            if (clone.layout === "colItem" || clone.layout === "dynamicItem") {
                let uId = "fd_" + getSimpleId();
                clone.id = uId;
                clone._id = uId;
                tempActiveData = clone;
            } else {
                let uId = "row_" + getSimpleId();
                clone.id = uId;
                clone._id = uId;
                tempActiveData = clone;
            }

            this.$emit("changeActiveItem", tempActiveData);
        },
        // 拖拽开始
        onStart(obj) {},
        // 拖拽结束
        onEnd(obj) {
            console.log("[onEnd]obj:", obj, "tempActiveData:", tempActiveData)
            console.log("[onEnd]objfrom-to:", obj.from,obj.to)
            if (obj.from !== obj.to) {
                if (this.isRepeatAddKit(tempActiveData)) {
                    this.$message.warning("套件不能同时存在");
                    return
                }
                if (this.isRepeatAddKit2(tempActiveData)) {
                    this.$message.warning("套件不能同时存在");
                    return
                }
                if(this.isExitDayStudentKit(tempActiveData)){
                    this.$message.warning("已有该组件");
                    return
                }
                let applicantArr = ["applicantStu", "applicant"];
                if(applicantArr.includes(tempActiveData.key) && this.designList.filter(item => applicantArr.includes(item.key)).length > 0){
                    this.$message.warning("已有申请人组件");
                    return
                }
                this.activeData = tempActiveData;
                this.$emit("changeActiveItem", this.activeData);
                if (obj.to.className.indexOf("row-drag") < 0) {
                    this.designList.splice(obj.newIndex, 0, this.activeData);
                    this.$emit("setNewDesignList", this.designList);
                }

                this.$emit("changeActiveData", this.activeData);
            } else {
                this.$emit("changeActiveItem", {});
            }
        },
        // 判断是否是套件同时存在
        isRepeatAddKit(addComp) {
            console.log("[addComponent]addComp:", addComp, "this.designList:", this.designList, "this.componentLibraryList:", this.componentLibraryList);
            if (
                this.componentLibraryList.find(e => e.compType === addComp.compType) // 判断要添加的组件是否是套件
                && this.designList.find(e => this.componentLibraryList.find(i => i.compType === e.compType)) // 判断表单中是否已有套件
            ) {
                return true
            } else {
                return false
            }
        },
        // 判断是否是系统套件同时存在
        isRepeatAddKit2(addComp) {
            if (
                this.commonComponents.find(e => e.compType === addComp.compType) // 判断要添加的组件是否是套件
                && this.designList.find(e => this.commonComponents.find(i => i.compType === e.compType)) // 判断表单中是否已有套件
            ) {
                return true
            } else {
                return false
            }
        },
        // 判断:走读申请套件是否与 申请人、申请人（学生）、时间组件并存
        isExitDayStudentKit(addComp){
            let disabledArray = ["applicant","applicantStu","dateTime"]
            // 首先判断拖入的是不是走读申请套件
            // console.log('isExitDayStudentKit:addComp:',addComp)
            if(addComp.compType == 'kitsDayStudent'){
                // console.log('加入的是kitsDayStudent')
                // designList：视图中已经存在的组件
                if(this.designList.length>0){
                    // 已有组件/套件则判断视图中是不是存在 申请人、申请人（学生）、时间组件
                    // console.log(this.designList.some(item => disabledArray.includes(item.compType)),'是否存在')
                    if(this.designList.some(item => disabledArray.includes(item.compType))){
                        return true
                    }else{
                        // 视图中不存在组件则直接加入走读申请套件
                        return false
                    }
                }else{
                    // console.log('加入的是kitsDayStudent并且没有其他组件')
                    return false
                }
            }else{
                // console.log('加入的不是kitsDayStudent')
                // 如果不是走读申请套件则校验视图中是否存在走读申请套件
                // 判断添加的是否为disabledArray数组中的组件
                if(disabledArray.find(e => e === addComp.compType)){
                    if(this.designList.find(e => e.compType === 'kitsDayStudent')){
                        // console.log('视图中已经存在kitsDayStudent')
                        return true
                    }else{
                        // console.log('视图不存在kitsDayStudent')
                        return false
                    }
                }

            }

        },

        // 点击添加组件/套件
        addComponent(element) {
            if (element.draggable === false) {
                return
            }
            let applicantArr = ["applicantStu", "applicant"];
            if(applicantArr.includes(element.key) && this.designList.filter(item => applicantArr.includes(item.key)).length > 0){
                this.$message.warning("已有申请人组件");
                return
            }
            if (this.isRepeatAddKit(element)) {
                this.$message.warning("套件不能同时存在");
                return
            }
            if (this.isRepeatAddKit2(element)) {
                this.$message.warning("套件不能同时存在");
                return
            }
            if(this.isExitDayStudentKit(element)){
                this.$message.warning("已有该组件");
                return
            }
            this.cloneComponent(element)
            this.activeData = tempActiveData;
            this.designList.push(this.activeData);
            this.$emit("changeActiveItem", this.activeData);
            this.$emit("setNewDesignList", this.designList, "click");
        },
        /**
         * @Description: 切换组件库
         * @DoWhat: 切换组件库
         * @UseScenarios: 切换组件库
         * @Attention: 切换组件库
         * @Author: 武东超
         * @Date: 2023-03-20 11:32:53
         */
         handlerClickLibraryTypeItem (data) {
            this.$emit('handlerClickLibraryTypeItem', data)
         },
        /**
         * @Description: 打开组件说明
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-03 15:01:10
         */
         openDes () {
            this.showDes = !this.showDes;
         },
        getIconName(name) {
            if (icons.includes(name)) {
                return name
            } else {
                return 'default'
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.component-library {
    width: 273px;
    height: calc(100vh - 150px);
    overflow: auto;
    background-color: #ffffff;
    border-radius: 4px 4px 0px 0px;

    .library-type {
        display: flex;
        align-items: center;
        height: 44px;
        border-bottom: 1px solid #EDEFF2;

        .library-type-item {
            position: relative;
            height: 44px;
            line-height: 40px;
            margin: 0 15px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6D6F73;
            box-sizing: border-box;
            // border-bottom: 2px solid#ffffff;
            .des {
                position: absolute;
                top: 2px;
                right: -20px;
                width: 14px;
                height: 14px;
                margin-left: 6px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .library-type-item.current {
            font-weight: bold;
            color: #2B2F33;
            border-bottom: 2px solid #3C7FFF;
        }
    }

    .library-detail {
        box-sizing: border-box;
        padding: 0 16px 16px;
        .des-wrapper {
            margin: 20px 16px;
            .kits-des {
                width: 202px;
                background-color: #F2F9FF;
                border: 1px dashed #3C7FFF;
                border-radius: 4px;
                box-sizing: border-box;
                padding: 16px 14px;
                .kits-title {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #2B2F33;
                    padding-bottom: 16px;
                    .close {
                        width: 11px;
                        height: 11px;
                        cursor: pointer;
                    }
                }
                .kits-content {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #808487;
                    line-height: 20px;
                }
            }
        }

        .components-draggable {
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(auto-fill, 110px);
            grid-gap: 12px;
            overflow: hidden;
        }

        .components-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 32px;
            float: left;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            background: #ffffff;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            cursor: pointer;
            padding-left: 5px;
            box-sizing: border-box;

            .icon-wrap {
                text-align: center;
                width: 24px;
                padding-top: 1px;
            }
            .comp-name{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .iconfont-oa {
                line-height: 14px;
                font-size: 14px;
                color: #333333;
            }

            &:hover {
                color: #3c7fff;
                border-color: #3c7fff;

                .iconfont-oa {
                    color: #3c7fff;
                }
            }
        }

        .draggable {
            display: flex;
            align-items: center;
            width: 100%;
            height: 32px;
            float: left;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3c7fff;
            background: #ffffff;
            border: 1px solid #3c7fff;
            border-radius: 4px;
            cursor: pointer;
            padding-left: 5px;
            box-sizing: border-box;

            .icon-wrap {
                text-align: center;
                width: 24px;
                padding-top: 1px;
            }
            .comp-name{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .iconfont-oa {
                line-height: 14px;
                font-size: 14px;
                color: #3c7fff;
            }
        }
    }
}
</style>
