<script>
import draggable from 'vuedraggable'
import render from '@/assets/data/formDesign/render'
import { getSimpleId } from "@/assets/data/formDesign/utils/IdGenerate";
import {dynamicTableAllowedItems,tableAllowedItems} from "@/assets/data/formDesign/formConf.js";
// import dynamicTable from '@/assets/data/formDesign/dynamic/dynamicTable'
// import dynamicTableItem from '@/assets/data/formDesign/dynamic/dynamicTableItem'
// import fancyTable from '@/assets/data/formDesign/extend/fancyTable'
//import fancyTableItem from '@/assets/data/formDesign/extend/fancyTableItem'
/**
 * 动态表单允许增加的组件列表
 */
const components = {
  itemBtns(h, element,parent) {
    if (element.key == 'departureTime' || element.key == 'returnTime') return [];
    const { deleteItem, copyItem } = this.$listeners;
    return [
      /*<span class="drawing-item-delete delete-item" title="删除" onClick={event => {
          deleteItem(element,parent); event.stopPropagation();
        }}>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAv1JREFUSEvFl09IFFEcx7+/2X+CO7Op0J+TFWGgpc566FDdShPqVEIQQc5qh/6QHTp0yroGZUQX0dlD1CGDDhWk1c2IDjprlGFEWJciUHNGqXV35xdvy2130Z1RdvXd5s173897v/n95n0fIa9Z7WotS9wBplYA2wAE8se4fI4D+AziZ2RTnxw1xrPn0eIDt9X6Tdl/g0BtIO6RUp4n5fLsR7r9SQisuPH5HYF5K1Rje1KHwdQFxiN5ruwiDbz+JcTS4L/QwFNimEmfpFX2jsyumFRgwvTpppA3afcDqJCteCsNjC+kwbNa+A7B3iLrsaMEcDGhmYgCZLWrD5n4W0iPnSOrs6GOU9LLRNxfU3XvjVkK6KLm1Ik9ii+wMEEe+wBZWuNNMM3IUeNaKaGL2mak8TIxNpKpqR8k4HhQN8bWAjx3qmm3LdkPBDgul1uK2+w1NXUSQHVOaTC+ylEjp2+5TYhEtuSAJcCs6EamrNZi14JZEGxq6jCAvatbDA8remz/UnNLDMYrRTf2rQqcyURNFT8UxeXOTUU3QoXGOu44C/weQK1L8LiiG3VFAVtaeJDBzUIs6ZWqvUn7S7Zwdh+BhmR9tKUoYFNT+wBEhJioABGqbOGcPoauRI302OWa61BbWuMVBnW7ARO4W9ZjV4sCNiOqBoY4XZx3TIgo/YZeFPBPraFZgjToBmzDbtmgjw0VBZx2JQSR2UgRtnoY4reZadl9xKjLdxv5i3D9jf8dZ67MQSLuDzkdr67BYsWmpk4BqHSo5WlFN6qc6n1FYKs93MPEFwqJEtMtOTra5Rbs6lgUx9mcHLjOjJMgVOSIM2aIcDdoxS8JP1UI/ONMbbDsd+Db2huBjnC9neL762N9bGkTWZH6Xcye54m4f6dTNjp9O6f3GbNHqYP/7S3zZjlqHCu9vaXvIX307PoaehGi/CsMwX4c9PgmqHck4RTCpd6nq0Dx1jCkI8teYbInCoOPpKeTCYcA3g7AtxowgHkwJgn0AlKyT+5/+y5b5w9DnMmcZAe7qgAAAABJRU5ErkJggg==" alt="del"/>
      </span>*/
        <div class="drawing-item-operate">
            {
                element.draggable === true ?
                    null :
                    <div title="复制" class="drawing-item-icon drawing-item-copy" onClick={event => {
                        copyItem(element, parent);
                        event.stopPropagation();
                    }}>
                    </div>
            }
            <div title="删除" class="drawing-item-icon drawing-item-delete" onClick={event => {
                deleteItem(element,parent); event.stopPropagation();
            }}>
            </div>
        </div>
    ]
  }
}
const layouts = {
  colItem(h, element,parent) {
    let className = (this.activeItem.id === element.id) &&  element.compType !='EvaluateTeachers' &&  element.compType !='EvaluateTeachersBan' ? 'drawing-item active-from-item' : 'drawing-item'
    let labelWidth = element.labelWidth ? `${element.labelWidth}px` : null
    const {onActiveItemChange} = this.$listeners;
    return (
        <el-col class={className} span={element.span}>
          <el-form-item label={element.showLabel ? element.label : ''}
                        label-width={labelWidth}
                        required={element.required} nativeOnClick={event => { onActiveItemChange(element); event.stopPropagation()}}>
            <render key={element.id} conf={element} onInput={ event => {
                this.$set(element, 'value', event)
              }}
            ></render>
          </el-form-item>
          {components.itemBtns.apply(this, arguments)}
        </el-col>
    )
  },
  rowItem(h, element){
    const { onActiveItemChange } = this.$listeners
    const className = (this.activeItem.id === element.id) &&  element.compType !='EvaluateTeachers'&&  element.compType !='EvaluateTeachersBan' ? 'drawing-item drawing-row-item active-from-item' : 'drawing-item drawing-row-item'
    return (
        <el-col class={className} >
          <el-row  gutter={element.gutter}  nativeOnClick={event => { onActiveItemChange(element); event.stopPropagation()}}>
            <span class="component-name">{element.id}</span>
            <div class="drag-wrapper" style="padding-left: 7.5px; padding-right: 7.5px;">
              {
                element.columns.map((item,index) =>{
                  return (
                    <el-col class="drag-col-wrapper"  span={item.span}>
                      <draggable class="drag-wrapper row-drag" v-model={item.list} animation="100" group="componentsGroup"
                      onAdd={(e)=>{this.handlerAdd(e,item,element)}}
                      >
                        {
                          item.list.map((obj,objIndex)=>{
                            return renderChildren.call(this,h,obj,element)
                          })
                        }
                      </draggable>
                    </el-col>
                  )
                })
              }
            </div>
          </el-row>
          {components.itemBtns.call(this,h,element)}
        </el-col>
    )
  },
}
/**
 * 生成row的子选项
 */
function renderChildren(h, element,parent) {
  const layout = layouts[element.layout];
  if (layout) {
    return layout.call(this, h, element,parent)
  }
  return layoutIsNotFound.call(this)
}

function layoutIsNotFound() {
  throw new Error(`没有与${this.element.layout}匹配的layout`)
}
export default {
  name:"designItem",
  components: {
    render,
    draggable,
    // dynamicTable,
    // dynamicTableItem,
    // fancyTable,
    // fancyTableItem
  },
  props: {
    model: {
      type: Object,
      default:{}
    },
    activeItem: {
      type: Object,
      default:{}
    }
  },
  data(){
    return {
      activeComponent:this.activeItem
    }
  },
  render(h) {
    const layout = layouts[this.model.layout]
    if (layout) {
      return layout.call(this, h, this.model)
    }
    return layoutIsNotFound.call(this)
  },
  methods:{

    handlerAdd(evt,item,row){
      if(evt.pullMode === 'clone'){
        if(!(evt.to.className.indexOf('row-drag')>-1&&this.activeItem.compType==='row')){
          item.list.splice(evt.newIndex,0,this.activeItem);
        }
      }else{
        if(evt.item.className.indexOf('el-row')>-1){  //防止row嵌套
          const newIndex = evt.newIndex;
          const oldIndex = evt.oldIndex;
          const rowItem = item.list[newIndex];
          item.list.splice(newIndex,1);
          this.$message.error('布局组件不允许被嵌套！');
          this.$emit('rowItemRollBack',rowItem,oldIndex);  //还原到原先的列表中
          return false;
        }
      }
    },
    /**
     * 动态表单
     */
    handlerDynamicAdd(evt,item){
      if(evt.pullMode === 'clone'){
        if(dynamicTableAllowedItems.includes(this.activeItem.compType)){
          item.columns.splice(evt.newIndex,0,this.activeItem);
        }else{
          this.$message.error('该组件不允许被放入动态表格内！');
        }
      }else{
        if(evt.item.className.indexOf('el-row')>-1){  //防止row嵌套
          const newIndex = evt.newIndex;
          const oldIndex = evt.oldIndex;
          const rowItem = item.columns[newIndex];
          item.columns.splice(newIndex,1);
          this.$message.error('布局组件不允许放入动态表格！');
          this.$emit('rowItemRollBack',rowItem,oldIndex);  //还原到原先的列表中
          return false;
        }
      }
    },
    handlerTableAdd(evt,td){
      if(evt.pullMode === 'clone'){
        if(tableAllowedItems.includes(this.activeItem.compType)){
          td.columns.splice(evt.newIndex,0,this.activeItem);
        }else{
          this.$message.error('该组件不允许被放入表格内！');
        }
      }
    },
    handlerCopyItem(evt,element,index){
      const item = element.columns[index];
      const clone = JSON.parse(JSON.stringify(item))
      let uId = "fd_"+getSimpleId();
      clone.id = uId;
      clone._id = uId;
      element.columns.push(clone);
    },
    handlerDeleteItem(evt,element,index){
      element.columns.splice(index,1);
    }
  }
}
</script>
