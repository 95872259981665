<!-- 配置信息 -->
<template>
    <div class="config_main content-bg">
        <div class="config_content">
            <div>
                <el-form
                    class="pzform"
                    ref="forms"
                    :model="form"
                    :rules="rules"
                    inline
                    label-width="120px"
                >
                    <el-form-item
                        prop="formName"
                        label="表单名称"
                    >
                        <el-input
                            v-model="form.formName"
                            placeholder="请输入"
                            class="labelWidth"
                            maxlength="30"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        prop="remark"
                        label="表单说明"
                    >
                        <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入"
                            maxlength="100"
                            show-word-limit
                            v-model="form.remark"
                            class="labelWidth"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        prop="userPopulation"
                        label="填写范围"
                    >
                        <el-radio-group
                            v-model="form.userPopulation"
                            :disabled="!isEditRadio"
                            @change="
                                userPopulationHandlerChange(form.userPopulation)
                            "
                        >
                            <el-radio :label="'2'">学生/家长</el-radio>
                            <el-radio :label="'1'">教职工</el-radio>
                            <el-radio :label="'3'">不限范围</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                        prop="organInfoName"
                        v-if="form.userPopulation && form.userPopulation != '3'"
                        label=" "
                        label-width="120px"
                    >
                        <el-input
                            placeholder="请输入"
                            v-model="form.organInfoName"
                            class="labelWidth"
                            @focus="focus('initiate')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        prop="allowEdit"
                        label="再次编辑"
                    >
                        <el-radio-group v-model="form.allowEdit">
                            <el-radio :label="'1'">允许再次编辑</el-radio>
                            <el-radio :label="'0'">不可再次编辑</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                        prop="releaseTime"
                        label="发布时间"
                    >
                        <el-date-picker
                            class="labelWidth"
                            v-model="form.releaseTime"
                            type="datetime"
                            placeholder="选择日期时间"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm:00"
                        >
                            >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item
                        prop="deadline"
                        label="截止时间"
                    >
                        <el-date-picker
                            class="labelWidth"
                            v-model="form.deadline"
                            type="datetime"
                            placeholder="选择日期时间"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm:00"
                        >
                            >
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConfigurationInformation",
    props: ["form", "rules", "isEditRadio"],
    data() {
        return {
            props: {multiple: true},
        };
    },
    mounted() {
        console.debug(JSON.parse(JSON.stringify(this.rules)), "rules");
        this.$emit(
            "userPopulationHandlerChange",
            this.form.userPopulation,
            true,
        );
    },
    methods: {
        /**
         * @Description: 焦点获取
         * @Author: 周浩
         * @Date: 2024-8-8 11:52:44
         */
        focus(key) {
            this.$emit("inputFocus", key);
        },
        /**
         * @Description: 所选范围
         * @Author: 周浩
         * @Date: 2024-8-8 14:32:04
         */
        userPopulationHandlerChange(userPopulation) {
            this.$emit("userPopulationHandlerChange", userPopulation);
        },
    },
};
</script>

<style lang="scss" scoped>
.config_main {
    width: 100%;
    height: calc(100vh - 150px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 960px;
    .config_content {
        width: 960px;
        height: 100%;
        margin: 10px 0px;
        background: #ffffff;
        border-radius: 8px 8px 0px 0px;
        box-sizing: border-box;
        padding: 40px 80px;
        overflow: auto;
    }
}
::v-deep {
    .pzform {
        .el-form-item {
            width: 100%;
        }
        .el-form-item__content {
            width: calc(100% - 120px) !important;
        }
    }
}

.labelWidth {
    width: calc(100% - 0px);
}
</style>
