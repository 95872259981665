<template>
    <!-- ComponentProperties 组件属性 -->
    <div class="component-properties">
        <Title
            :textAlign="false"
            :text="currentProperties"
        />
        <div class="field-box" ref="fieldBox">
            <el-form
                size="small"
                label-position="right"
                label-width="80px"
            >
                <div
                    v-for="(item, index) in cmps"
                    :key="index"
                >
                    <component
                        v-if="item.name === activeItem.compType"
                        :getFormId="getFormId"
                        :props="activeItem"
                        :is="item.content"
                        :selctOption="selctOption"
                    ></component>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import Title from "./Sub/Title.vue";
import reg from "@/assets/data/formDesign/register";

export default {
    name: "ComponentProperties",
    components: {
        Title,
    },
    props: {
        activeItem: {
            type: Object,
            default: function () {
                return {};
            },
        },
        itemList: {
            type: Array,
            default: function () {
                return [];
            },
        },
        currentProperties: {
            type: String,
            default: () => {
                return "组件属性";
            },
        },
        selctOption:[String,Number]
    },
    watch: {
        activeItem: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                console.debug(JSON.parse(JSON.stringify(newValue)),"aaaaa");
                if(newValue.compType != "EvaluateTeachers"){
                    this.$eventBus.$emit("EvaluateTeachers");
                }
                if(newValue.compType != "EvaluateTeachersBan"){
                    this.$eventBus.$emit("EvaluateTeachersBan");
                }

            }
        }
    },
    data() {
        return {
            cmps: reg,
            formIdArray: [],
        };
    },
    created() {
        this.cmps.forEach((c) => {
            c.content =
                require(`@/assets/data/formDesign/configs/${c.name}`).default;
        });
    },
    methods: {
        getFormId(itemId) {
            this.formIdArray = [];
            Array.from(this.itemList, (item) => {
                if (item.compType === "row") {
                    Array.from(item.columns, (column) => {
                        Array.from(column.list, (col) => {
                            if (col._id !== itemId) {
                                this.formIdArray.push(col.id);
                            }
                        });
                    });
                } else {
                    if (item._id !== itemId) {
                        this.formIdArray.push(item.id);
                    }
                }
            });
            return this.formIdArray;
        },
    },
};
</script>

<style lang="scss" scoped>
.component-properties {
    width: 300px;
    // height: 100%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 4px 4px 0px 0px;

    .field-box {
        box-sizing: border-box;
        padding: 21px 16px;
    }
}
</style>
